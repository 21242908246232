var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "not-found" },
    [
      _c(
        "router-link",
        { attrs: { to: { name: "index" } } },
        [
          _vm._t("logo", function () {
            return [
              _c("antd-icon", {
                staticClass: "logo",
                attrs: { type: "logo-easycash-o" },
              }),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("img", {
        staticClass: "not-found-img",
        attrs: { src: _vm.notFoundUrl, alt: "" },
      }),
      _vm._v(" "),
      _c(
        "router-link",
        { attrs: { to: { name: "home" } } },
        [
          _vm._t("back", function () {
            return [
              _c("span", { staticClass: "back-btn" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("goHome")) +
                    "\n            "
                ),
              ]),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }