<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguan.com -->
<!-- @date 2020-4-16 12:18:43 -->
<!-- @desc index.vue -->

<template>
    <not-found>
        <div slot="logo" />
    </not-found>
</template>

<script>
    import NotFound from 'easycash/app/not-found';

    export default {
        components: {'not-found': NotFound}
    };
</script>
