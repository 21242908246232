<!-- @Author: xiaodongyu -->
<!-- @Date: 2019/9/16-15:57 -->
<!-- @Last Modified by: xiaodongyu -->
<!-- @Last Modified time: 2024-05-21 14:53:01 -->

<template>
    <div class="not-found">
        <router-link :to="{name: 'index'}">
            <slot name="logo">
                <antd-icon
                    type="logo-easycash-o"
                    class="logo"
                />
            </slot>
        </router-link>

        <img
            :src="notFoundUrl"
            class="not-found-img"
            alt=""
        >

        <router-link :to="{name: 'home'}">
            <slot name="back">
                <span class="back-btn">
                    {{ $t('goHome') }}
                </span>
            </slot>
        </router-link>
    </div>
</template>

<script type="text/babel">
    import {uploadWebBizEvent} from '@shared/client/util/web-biz-event-sdk';

    import notFoundUrl from './not-found.png';

    export default {
        name: 'NotFound',

        title: 'Not Found',

        data() {
            return {
                notFoundUrl
            };
        },

        mounted() {
            uploadWebBizEvent(`not-found${this.$route.fullPath}`);
        }
    };
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~easycash/common/style/variables/variables.scss";

    .not-found {
        margin: 0 auto;
        width: 80%;
        max-width: 800px;
        text-align: center;

        .logo {
            margin-top: 10%;
            width: 50%;
            max-width: 250px;
            color: $green-light;
            font-size: 30px;
        }

        .not-found-img {
            display: block;
            margin: 0 auto;
            width: 100%;
            max-width: 500px;
        }

        .back-btn {
            display: inline-block;
            padding: 8px 20px;
            background-color: $green-light;
            color: $white-base;
            font-weight: bold;
            border-radius: 18px;
        }
    }
</style>
